<template>
  <div>
    <MediaCard
      :model="model"
      :show-link="false"
      @onHandleClick="onHandleButtonPlay"
    />
    <Modal
      ref="modal"
      :is-share-button="true"
      :is-full-width-content="true"
      :is-mobile-fullscreen="true"
      :share-button="shareButtonModel"
      :reverse-actions-color="true"
      @onCloseClick="onCloseModalClick"
      @onShareClick="onShareModalClick"
    >
      <FundamentalBlockLoader
        v-if="visibleModal"
        :model="fundamentalBlockModel"
        :button-text="introSliderButtonText"
      />
    </Modal>
  </div>
</template>

<script>
import { ref } from 'vue'
import MediaCard from '@/components/blocks/MediaCard.vue'
import Modal from '@/components/Modal.vue'
import FundamentalBlockLoader from '@/components/blocks/FundamentalBlockLoader.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    MediaCard,
    Modal,
    FundamentalBlockLoader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    introSliderButtonText: {
      type: String,
      default: ''
    }
  },
  setup() {
    const visibleModal = ref(false)
    const fundamentalBlockModel = ref({})
    const shareButtonModel = ref({})
    const modal = ref(null)

    function onHandleButtonPlay({ modalModel }) {
      fundamentalBlockModel.value = modalModel
      shareButtonModel.value = modalModel.shareButton
      visibleModal.value = true
      modal.value.onOpenModal()
    }

    function onCloseModalClick() {
      gtmUtil.fundamentalsClose()
    }

    function onShareModalClick() {
      gtmUtil.fundamentalsShare()
    }

    return {
      fundamentalBlockModel,
      modal,
      shareButtonModel,
      visibleModal,
      onHandleButtonPlay,
      onCloseModalClick,
      onShareModalClick
    }
  }
}
</script>
