<template>
  <ul class="c-accordion u-bare-list">
    <li
      v-for="(item, index) in accordionData"
      :key="index"
      :class="{'c-accordion-item': true, 'c-accordion-item-active' : item && item.isOpen }"
    >
      <h3
        v-if="item.header"
        class="c-accordion-heading u-font-normal"
      >
        <button
          class="c-accordion-heading-toggler"
          @click="onHandleClick(item)"
        >
          {{ item.header }}
          <Icon
            :icon="iconChevron"
            :size="16"
            class="c-accordion-heading-icon"
          />
        </button>
      </h3>
      <div
        v-if="item.text"
        class="c-accordion-body-text"
        v-html="item.text"
      />
    </li>
  </ul>
</template>

<script>
import { reactive } from 'vue'
import Icon from '@/components/Icon.vue'
import iconChevron from '@/assets/img/icons/chevron--down.svg'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const accordionData = reactive(props.model)

    const onHandleClick = (item) => {
      if (item.isOpen) {
        gtmUtil.accordionClose(item.header)
      } else {
        gtmUtil.accordionOpen(item.header)
      }
      item.isOpen = !item.isOpen
    }

    return {
      accordionData,
      onHandleClick,
      iconChevron
    }
  }
}
</script>
