<template>
  <transition name="recipe-navigation">
    <div
      v-if="showNavigation"
      ref="recipeNavigation"
      :style="navigationStyles"
      class="c-recipe-navigation"
    >
      <div class="c-recipe-navigation__recipe-title">
        {{ model.recipeName }}
        <ShareButton />
      </div>

      <ul class="c-recipe-navigation__list u-bare-list">
        <li
          v-for="section in navSections"
          :key="section.id"
          :class="{ 'active': section.active }"
          class="c-recipe-navigation__list-item"
        >
          <button @click="scrollToSection(section)">
            {{ section.name }}
          </button>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import {
  onMounted, onUnmounted, ref, computed
} from 'vue'
import ShareButton from '@/components/navigation/ShareButton.vue'

export default {
  components: { ShareButton },
  props: {
    model: {
      type: Object,
      required: true
    },
    triggerElementSelector: {
      type: String,
      default: '.c-item-hero'
    }
  },
  setup(props) {
    const recipeNavigation = ref(false)
    const showNavigation = ref(false)
    const navSections = ref(props.model.navigationSections)
    const navigationStyles = computed(() => (props.model.backgroundImage?.src
      ? `background-image: url('${props.model.backgroundImage.src}')`
      : ''))

    /*  */
    const scrollToSection = (section) => {
      const elementPosition = document.querySelector(`#${section.id}`).getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - recipeNavigation.value.offsetHeight

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }

    /* Intersection observer setup for page sections */
    function handlePageIntersection(entries) {
      entries.forEach((entry) => {
        navSections.value.find((section) => section.id === entry.target.id).active = entry.isIntersecting
      })
    }

    const pageObserver = new IntersectionObserver(handlePageIntersection, {
      rootMargin: '-50px',
      threshold: 0.1
    })

    /* Scroll event for triggering the visibility of the navigation bar */
    const handleScroll = () => {
      const scrollTarget = document.querySelector(props.triggerElementSelector)
      if (window.scrollY > (scrollTarget.offsetTop + scrollTarget.offsetHeight)) {
        if (!showNavigation.value) showNavigation.value = true
      } else if (showNavigation.value) showNavigation.value = false
    }

    /* Add or remove observers and event listeners */
    onMounted(() => {
      props.model.navigationSections.forEach((section) => {
        pageObserver.observe(document.querySelector(`#${section.id}`))
      })
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      recipeNavigation,
      showNavigation,
      navSections,
      navigationStyles,
      scrollToSection
    }
  }
}
</script>
