<template>
  <div
    :class="{'c-tip': true, 'c-tip--open' : isOpen }"
  >
    <div class="c-tip__header">
      <strong class="u-text-uppercase"> {{ tipLabel }} </strong>
      <button @click="onHandleClick()">
        <Icon
          :icon="iconChevron"
          :size="16"
        />
        <span class="u-text-underline">{{ toggleLabel }}</span>
      </button>
    </div>

    <div
      ref="contentWrapper"
      class="c-tip__content-wrapper"
    >
      <div class="c-tip__content">
        <div
          v-if="model.image"
          class="c-tip__image-wrapper"
        >
          <Picture
            v-bind="model.image"
            :mobile="[200,300]"
            class="c-tip__image"
          />
        </div>

        <div class="c-tip__text">
          <div
            :class="{ 'u-mb--xs': model.link }"
          >
            {{ model.text }}
          </div>
          <div
            v-if="model.link"
            class="c-tip__link"
          >
            <a :href="model.link.url">{{ model.link.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import Picture from '@/components/Picture.vue'
import Icon from '@/components/Icon.vue'
import iconChevron from '@/assets/img/icons/chevron--down.svg'

export default {
  components: {
    Picture,
    Icon
  },
  props: {
    openOnLoad: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: String,
      default: 'Show'
    },
    hideLabel: {
      type: String,
      default: 'Hide'
    },
    tipLabel: {
      type: String,
      default: 'Tip'
    },
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isOpen = ref(true)
    const contentWrapper = ref(null)
    const toggleLabel = ref('')
    const timeout = ref(null)

    const expand = () => {
      toggleLabel.value = props.hideLabel
      contentWrapper.value.style.maxHeight = `${contentWrapper.value.scrollHeight}px`

      timeout.value = setTimeout(() => {
        contentWrapper.value.style.maxHeight = 'none'
      }, 200)
    }

    const collapse = () => {
      toggleLabel.value = props.showLabel
      contentWrapper.value.style.maxHeight = `${contentWrapper.value.scrollHeight}px`

      // Need to use a timeout to trigger the transition
      setTimeout(() => {
        contentWrapper.value.style.maxHeight = '0'
      })
    }

    const toggleState = () => {
      clearTimeout(timeout.value)
      if (isOpen.value) {
        expand()
      } else {
        collapse()
      }
    }

    onMounted(() => {
      isOpen.value = props.openOnLoad
      toggleState()
    })

    const onHandleClick = () => {
      isOpen.value = !isOpen.value
      toggleState()
    }

    return {
      isOpen,
      contentWrapper,
      toggleLabel,
      iconChevron,
      onHandleClick
    }
  }
}
</script>
