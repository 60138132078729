<template>
  <transition name="modal">
    <focus-trap
      v-if="isModalOpen"
      v-model="isModalOpen"
      :initial-focus="() => closeButton"
    >
      <div class="c-modal">
        <div
          ref="modalContainer"
          v-click-away="onClickAway"
          class="c-modal__container"
          :class="{ 'is-fullscreen': isMobileFullscreen, 'c-modal__container--reverse-action-color': reverseActionsColor }"
        >
          <button
            ref="closeButton"
            class="c-modal__close-button"
            @click="onCloseModal"
          >
            <Icon
              :icon="iconClose"
              :size="32"
            />
            <span class="u-hide-on-screenonly">{{ closeText }}</span>
          </button>
          <ShareButton
            v-if="isShareButton"
            :model="shareButton"
            class="c-modal__share-button"
            @onShareClick="onShareClick"
          />

          <div
            class="c-modal__content"
            :class="{ 'is-full-width': isFullWidthContent }"
            body-scroll-lock-ignore
          >
            <slot />
          </div>
        </div>
      </div>
    </focus-trap>
  </transition>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { directive } from 'vue3-click-away'
import { FocusTrap } from 'focus-trap-vue'
import Icon from '@/components/Icon.vue'
import iconClose from '@/assets/img/icons/close.svg'
import ShareButton from '@/components/navigation/ShareButton.vue'

export default {
  directives: {
    ClickAway: directive
  },
  components: {
    FocusTrap,
    Icon,
    ShareButton
  },
  props: {
    closeText: {
      type: String,
      default: 'close'
    },
    isShareButton: {
      type: Boolean,
      default: false
    },
    shareButton: {
      type: Object,
      default: null
    },
    reverseActionsColor: {
      type: Boolean,
      default: false
    },
    isFullWidthContent: {
      type: Boolean,
      default: false
    },
    isMobileFullscreen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const modalContainer = ref(null)
    const closeButton = ref(null)
    const isModalOpen = ref(false)

    const onOpenModal = () => {
      isModalOpen.value = true
      document.querySelector('main').classList.add('increased-stack')
      setTimeout(() => {
        disableBodyScroll(modalContainer.value, {
          allowTouchMove: (el) => {
            while (el && el !== document.body) {
              if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                return true
              }
              // eslint-disable-next-line no-param-reassign
              el = el.parentElement
            }
            return false
          }
        })
      }, 10)
    }
    const onCloseModal = () => {
      isModalOpen.value = false
      document.querySelector('main').classList.remove('increased-stack')
      enableBodyScroll(modalContainer.value)
      context.emit('onCloseClick')
    }

    const onClickAway = () => {
      onCloseModal()
    }
    const onKeydown = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        onCloseModal()
      }
    }

    const onShareClick = () => {
      context.emit('onShareClick')
    }

    onMounted(() => {
      document.addEventListener('keydown', (e) => onKeydown(e))
    })
    onUnmounted(() => {
      clearAllBodyScrollLocks()
      document.removeEventListener('keydown', (e) => onKeydown(e))
    })

    return {
      modalContainer,
      closeButton,
      isModalOpen,
      onOpenModal,
      onCloseModal,
      onClickAway,
      onShareClick,
      iconClose
    }
  }
}
</script>
