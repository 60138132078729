<template>
  <button
    class="c-button--icon c-header__share u-flex"
    :class="{
      'hover': !isMobile && (hover || clicked)
    }"
    @click="onShareClick"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @focus="hover = true"
    @blur="hover = false"
  >
    <span
      v-if="displayText"
      class="c-header__share-display"
    >
      {{ displayText }}
    </span>
    <Icon
      :icon="shareIcon"
      :size="24"
      class="c-header__icon--share u-ml--s"
    />
  </button>
</template>

<script>
import { ref, computed } from 'vue'
import { useMq } from 'vue3-mq'
import shareIcon from '@/assets/img/icons/share.svg'
import Icon from '@/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          clickText: '',
          hoverText: '',
          customShareUrl: '',
          delay: 1000
        }
      }
    }
  },
  setup(props, context) {
    const hover = ref(false)
    const clicked = ref(false)
    const displayText = computed(() => (clicked.value ? props.model.clickText || '' : props.model.hoverText || ''))
    const isMobile = useMq().current === 'mobile' || useMq().current === 'tablet'

    const copyComplete = () => {
      // TODO: Add tracking
      clicked.value = true
      setTimeout(() => {
        clicked.value = false
        hover.value = false
      }, props.model.delay)
    }

    const copyUrl = (url) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(url).then(() => {
          copyComplete()
        }).catch()
      } else {
        const dummy = document.createElement('input')
        document.body.appendChild(dummy)
        dummy.value = url
        dummy.select()
        dummy.focus()
        document.execCommand('copy')
        document.body.removeChild(dummy)
        copyComplete()
      }
    }

    const shareUrl = (data) => {
      if (navigator.share) {
        navigator.share(data).then(() => {
          // TODO: Add tracking here
        }).catch()
      } else {
        copyUrl(data.url)
      }
    }

    const onShareClick = () => {
      context.emit('onShareClick')
      if (props.model.customShareUrl) {
        shareUrl({
          url: props.model.customShareUrl
        })
        return
      }
      shareUrl({
        title: document.title,
        url: window.location.href
      })
    }

    return {
      hover,
      clicked,
      displayText,
      isMobile,
      shareIcon,
      onShareClick,
      shareUrl,
      copyUrl
    }
  }
}
</script>
